.square {
    height: 50px;;
    width: 50px;
    border: 2px solid;
}

body {
    font-family: Arial, Helvetica, sans-serif;
    padding-left: 10%;
    padding-right: 10%;
}

.homeWrapper {
    position: relative;
}

.sentence {
    font-size: 1.1875rem;
    line-height: 1.5625rem;
}

@media screen and (max-width: 1000px) {
    .sentenceMargin {
        margin-top: 38%;
    }
    .maxWidthMainLogo {
        max-width: 80%;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1270px) {
    .sentenceMargin {
        margin-top: 33%;
    }
    .maxWidthMainLogo {
        max-width: 80%;
    }
}

@media screen and (min-width: 1271px) {
    .sentenceMargin {
        margin-top: 25%;
    }

    .maxWidthMainLogo {
        max-width: 55%;
    }
}

.details {
    height: auto;
    content:url(resources/triva_architects_logo_2.png);
}

.mainLogoProps {
    position: fixed;
    top: 0;
    left: 10%;
    cursor: pointer;
    width: 100%;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
    position: absolute;
    left: 7%;
    right: 7%;
    top: 4%;
    bottom: 7%;
    margin: auto;
    background: white;
    padding: 5%;
    /*font-size: 3vw;*/
    font-size: 1.1875rem;
    line-height: 1.5625rem;
}

.contactContent {
    width: 100%;
    overflow: hidden;
    text-align: center;
}

div {
    display: block;
}

.marginTop {
    margin-top: 3%;
}

.marginTop1 {
    margin-top: 1%;
}

.marginTop2 {
    margin-top: 2%;
}

.marginTop4 {
    margin-top: 4%;
}

.marginTop6 {
    margin-top: 6%;
}

.ok1 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/1_1.jpg);
    cursor: pointer;
}

.ok1_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/1_2.jpg);
}

.ok0 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/0_1.jpg);
    cursor: pointer;
}

.ok0_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/0_2.jpg);
}

.ok2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/2_1.jpg);
    cursor: pointer;
}
.ok2_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/2_2.jpg);
}
.ok2_3 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/2_3.jpg);
}

.ok3 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/3_1.jpg);
    cursor: pointer;
}

.ok3_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/3_2.jpg);
}

.ok4 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/4_1.jpg);
    cursor: pointer;
}

.ok4_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/4_2.jpg);
}

.ok5 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/5_1.jpg);
    cursor: pointer;
}

.ok5_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/5_2.jpg);
}

.ok6 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/6_1.jpg);
    cursor: pointer;
}

.ok6_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/6_2.jpg);
}

.ok7 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/7_1.jpg);
    cursor: pointer;
}

.ok7_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/7_2.jpg);
}

.ok8 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/8_1.jpg);
    cursor: pointer;
}

.ok8_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/8_2.jpg);
}

.ok9 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/9_1.jpg);
    cursor: pointer;
}

.ok9_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/9_2.jpg);
}

.ok10 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/10_1.jpg);
    cursor: pointer;
}

.ok10_2 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/10_2.jpg);
}

.ok11 {
    max-width: 100%;
    height: auto;
    content:url(resources/projekt1/redosljed/11_1.jpg);
    cursor: pointer;
}

.ok11_2 {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    content:url(resources/projekt1/redosljed/11_2.jpg);
}

.logo {
    height: 170px;
    width: 170px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    content:url(resources/logo.jpg);
}

h1 {
    text-align: center;
}

.development {
    position: absolute;
    bottom: 10px;
    right: 15px;
}

.buttonSize {
    font-size: 20px;
    margin: 4px 2px;
    cursor: pointer;
}
